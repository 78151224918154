.additional-heads-container {
  min-height: 100px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px 5px;
  .no-add-items {
    background-color: #e3e3e3;
    width: 97%;
    padding: 7px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
  }
  .item-input-container {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
    .item {
      padding: 0 10px;
    }
    .item-head {
      flex: 4;
    }
    .item-quantity {
      flex: 0.5;
      input {
        text-align: center;
      }
    }
    .item-amount {
      flex: 1.5;

      input {
        text-align: right;
      }
    }
    .item-action {
      flex: 0.2;
      cursor: pointer;
    }
    input {
      font-weight: bold;
      width: 100%;
      height: 30px;
      padding: 0 10px;
      &:focus {
        outline: none;
      }
    }
  }
}
