.sample-context-container {
  min-height: 400px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  .context-ratio-1 {
    margin-left: 70px;
    flex: 1;
    background-color: #eee;
    img {
      width: 100%;
    }
  }
  .context-ratio-3 {
    flex: 3;
    background-color: #eee;
    margin-left: 10px;
    margin-right: 70px;
    img {
      width: 100%;
    }
  }
  p {
    font-size: 18px;
    margin-top: 20px;
  }
}

.clinical-context-title {
  margin-bottom: 10px;
  width: 100%;
  cursor: pointer;
  background-color: #eee;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
  }
  a {
    transition: 0.3s;
    &.open {
      transform: rotate(90deg);
    }
  }
}

.dragon-container {
  min-height: 500px;
}

.pathology-viewer-container {
  // min-height: 500px;
  width: 100%;
}
