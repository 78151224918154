#aiConsultationModal {
  min-width: 900px;
  .modal-body {
    padding: 0 !important;
  }
}
.moichor-chat-screen {
  width: 100%;
  height: 100%;
  background: white;
  padding: 15px;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .chat-receipient-intro {
    img {
      height: 40px;
      margin-top: 0;
    }
    text-align: right;
    h3 {
      font-weight: bold;
      margin-bottom: 0;
      color: #333;
    }
    color: #777;
    .moichor-information {
      font-size: 12px;
      margin-top: 10px;
      text-align: justify;
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }

  .chat-messages-container {
    background: #f7f8fc;
    border-radius: 10px;
    overflow-x: scroll;
    padding: 15px;
    flex: 1;
    position: relative;
    display: flex;
    .ai-chat-messages {
      margin-top: auto;
      width: 100%;
      .message {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        width: 70%;
        p.role {
          margin-bottom: 0;
          padding-bottom: 0;
          font-size: 12px;
          text-transform: capitalize;
        }
        &.sender {
          margin-left: auto;
          .sender-icon {
            margin-left: 10px;
            margin-right: 0;
          }
          p.role {
            color: #ccc;
          }
        }
        &.receiver {
          .text-message {
            background-color: #1863a0;
            color: white;
            &.loading-chat {
              background-color: white;
            }
            p.role {
              color: #f2a2aa;
            }
          }
        }
        .text-message {
          flex: 1;
        }
        .sender-icon {
          margin-right: 10px;
          display: block;
          height: 50px;
          width: 50px;
          margin-top: 10px;
          background-color: #eee;
          border-radius: 50%;
          overflow: hidden;
          border: 3px solid white;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .text-message {
          border-radius: 10px;
          background-color: white;
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
          padding: 8px 15px 15px 15px;
          font-size: 15px;
          line-height: 1.7;
        }
      }
    }
  }
  .chat-action-fields {
    width: 100%;
    display: flex;
    margin-top: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    .input-field-container {
      flex: 1;
      input {
        width: 100%;
        padding: 15px 10px;
        border: none;
        font-weight: bold;
        &:focus {
          outline: none;
        }
      }
    }
    .action-button {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        align-items: center;
        color: #c43d4b;
        border-left: 1px solid #ccc;
        height: 100%;
        display: flex;
        padding: 0 15px;
        .simple-icon-paper-plane:before {
          font-weight: bold;
          display: inline-block;
          transform: rotate(40deg);
          font-size: 24px;
        }
      }
    }
  }
}
