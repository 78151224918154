.grid {
  display: grid;
}

// Grid Template Columns
@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
  }
}

// Grid Template Rows
@for $i from 1 through 12 {
  .grid-rows-#{$i} {
    grid-template-rows: repeat(#{$i}, minmax(0, 1fr));
  }
}

// Grid Column Span
@for $i from 1 through 12 {
  .col-span-#{$i} {
    grid-column: span #{$i} / span #{$i};
  }
}

// Grid Row Span
@for $i from 1 through 12 {
  .row-span-#{$i} {
    grid-row: span #{$i} / span #{$i};
  }
}

// Grid Column Start / End
@for $i from 1 through 13 {
  .col-start-#{$i} {
    grid-column-start: #{$i};
  }
  .col-end-#{$i} {
    grid-column-end: #{$i};
  }
}

// Grid Row Start / End
@for $i from 1 through 13 {
  .row-start-#{$i} {
    grid-row-start: #{$i};
  }
  .row-end-#{$i} {
    grid-row-end: #{$i};
  }
}

// Gap
@for $i from 1 through 12 {
  .gap-#{$i} {
    gap: #{$i * 0.25}rem;
  }
  .gap-x-#{$i} {
    column-gap: #{$i * 0.25}rem;
  }
  .gap-y-#{$i} {
    row-gap: #{$i * 0.25}rem;
  }
}

// Auto Flow
.grid-flow-row {
  grid-auto-flow: row;
}
.grid-flow-col {
  grid-auto-flow: column;
}
.grid-flow-row-dense {
  grid-auto-flow: row dense;
}
.grid-flow-col-dense {
  grid-auto-flow: column dense;
}
