// Absolute and Relative positioning classes
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

// Top, Left, Right, Bottom positioning classes
@each $direction in (top, right, bottom, left) {
  @for $i from 0 to 21 {
    $value: if($i == 0, 0, $i * 0.25) + if($i == 0, '', 'rem');
    .#{$direction}-#{$i} {
      #{$direction}: #{$value};
    }
  }
}

// Center positioning classes
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Define text sizes
$text-sizes: (
  'xs': 0.75rem,
  // 12px
  'sm': 0.875rem,
  // 14px
  'base': 1rem,
  // 16px
  'lg': 1.125rem,
  // 18px
  'xl': 1.25rem,
  // 20px
  '2xl': 1.5rem,
  // 24px
  '3xl': 1.875rem,
  // 30px
  '4xl': 2.25rem,
  // 36px
  '5xl': 3rem,
  // 48px
  '6xl': 3.75rem,
  // 60px
  '7xl': 4.5rem,
  // 72px
  '8xl': 6rem,
  // 96px
  '9xl': 8rem // 128px,,,,,,
);

// Generate text size classes
@each $name, $size in $text-sizes {
  .text-#{$name} {
    font-size: #{$size};
  }
}

// Padding values
$padding-sizes: (
  '0': 0,
  '0_5': 0.125rem,
  // Replacing `.` with `_`
  '1': 0.25rem,
  '1_5': 0.375rem,
  '2': 0.5rem,
  '2_5': 0.625rem,
  '3': 0.75rem,
  '3_5': 0.875rem,
  '4': 1rem,
  '5': 1.25rem,
  '6': 1.5rem,
  '7': 1.75rem,
  '8': 2rem,
  '9': 2.25rem,
  '10': 2.5rem,
  '11': 2.75rem,
  '12': 3rem,
  '14': 3.5rem,
  '16': 4rem,
  '20': 5rem,
  '24': 6rem,
  '28': 7rem,
  '32': 8rem,
  '36': 9rem,
  '40': 10rem,
  '44': 11rem,
  '48': 12rem,
  '52': 13rem,
  '56': 14rem,
  '60': 15rem,
  '64': 16rem,
  '72': 18rem,
  '80': 20rem,
  '96': 24rem,
);

// Generate padding classes
@each $size, $value in $padding-sizes {
  .p-#{$size} {
    padding: #{$value};
  }
  .pt-#{$size} {
    padding-top: #{$value};
  }
  .pr-#{$size} {
    padding-right: #{$value};
  }
  .pb-#{$size} {
    padding-bottom: #{$value};
  }
  .pl-#{$size} {
    padding-left: #{$value};
  }
  .px-#{$size} {
    padding-left: #{$value};
    padding-right: #{$value};
  }
  .py-#{$size} {
    padding-top: #{$value};
    padding-bottom: #{$value};
  }
}

// Margin values (same as padding values for consistency)
$margin-sizes: $padding-sizes;

// Generate margin classes
@each $size, $value in $margin-sizes {
  .m-#{$size} {
    margin: #{$value};
  }
  .mt-#{$size} {
    margin-top: #{$value};
  }
  .mr-#{$size} {
    margin-right: #{$value};
  }
  .mb-#{$size} {
    margin-bottom: #{$value};
  }
  .ml-#{$size} {
    margin-left: #{$value};
  }
  .mx-#{$size} {
    margin-left: #{$value};
    margin-right: #{$value};
  }
  .my-#{$size} {
    margin-top: #{$value};
    margin-bottom: #{$value};
  }
}
