.alert-message-prompt {
  min-height: 50px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  padding: 15px 15px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 20px;
  min-height: 100px;
  .alert-type {
    height: inherit;
    border-radius: 10px;
    width: 8px;
    background: #f1a92d;
  }
  .alert-icon {
    margin: auto 25px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: #f1a92d;
    display: flex;
    align-items: center;
    i {
      font-size: 30px;
      color: white;
    }
  }
  .alert-message-box {
    margin-top: 0px;
    .alert-title {
      font-size: 18px;
      line-height: 1;
      color: rgb(68, 68, 68);
      margin-bottom: 5px;
      font-weight: 400;
    }
    .alert-message {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.3;
      color: #94959a;
    }
  }
}
