@import './variables';
@import './custom_style_1';
@import './extended.modal';
@import './rack';
@import './accession';
@import './dicom_style';
@import './simple_table_design';
@import './skeleton_loader';
@import './moichor_custom';
@import './grid_system';
@import './flex_system';
@import './color_system';
@import './round_system';
@import './column_system';
@import './invoice';
@import './utils';
@import './sample_context_viewer';

.container-fluid,
.dashboard-wrapper {
  height: 100%;
}

@import './ai_chat_screen';

.list-title {
  font-weight: bold;
  cursor: pointer;
  transition: color 0.4s;

  &:hover {
    color: $theme-color-1;
  }
}

.variable-item {
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 10px;
  transition: background 0.4s;
  background: #f5f5f5;
  box-shadow: 2px 2px 4px -2px #00000060;

  &:hover {
    background: #00000012;

    .delete-variable-item {
      opacity: 1;
    }
  }

  span {
    cursor: default;
    font-size: 0.8rem;

    &.delete-variable-item {
      opacity: 0;
      transition: opacity 0.4s;
      cursor: pointer;
      padding: 0 10px;
    }
  }
}

.variable-container {
  max-height: 400px;
  height: auto;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  padding: 0px 10px 10px 0;
  overflow: auto;

  & > div {
    padding: 0 1px;
  }
}

.test-vars-section-name-input {
  font-size: 1rem;
  padding: 0;

  &:not(:focus):not(:hover) {
    border-color: transparent;
  }
}

.custom-answer__plain-text {
  overflow-wrap: break-word;
}

#refTestModal {
  max-width: 800px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141);
  }

  min-width: 600px;
}

.alert {
  border-radius: 25px;
}

.alert-level {
  padding: 2px 15px;
  color: white;
  border-radius: 15px;
  width: fit-content;

  &-message {
    background: #888888;
  }

  &-critical {
    background: #bb422f;
  }
}

.alert-status {
  padding: 2px 15px;
  color: white;
  border-radius: 15px;
  width: fit-content;

  &-expired {
    background: #888888;
  }

  &-active {
    background: #26884c;
  }
}

.card-heading {
  background: #ffffff00;
  box-shadow: none;
  font-weight: bold;

  .card-body {
    padding: 0 1.75rem;
  }
}

.list-item-card:hover {
  background-color: #f8f8f8;
}

.tooltip-inner {
  max-width: 700px;
}

#displayOptions {
  .date-range-sm {
    background: none !important;
    border: 1px solid #8f8f8f !important;
    border-radius: 15px !important;
    padding: 0.2rem 0.75rem 0.2rem 0.75rem !important;
    font-size: 0.76rem !important;
    line-height: 1.3 !important;
    color: #303030 !important;
    max-width: 10em !important;
  }

  .select-sm {
    height: 25px;

    .react-select__control {
      background: none !important;
      border: 1px solid #8f8f8f !important;
      border-radius: 15px !important;
      padding: 0rem 0rem 0rem 0.75rem !important;
      font-size: 0.76rem !important;
      line-height: 0.6 !important;

      color: #303030;
      min-height: 25px !important;
    }

    .react-select__value-container {
      padding: 0 !important;
      min-width: 100px !important;
    }

    .react-select__indicator {
      padding: 0 8px !important;
    }

    .react-select__menu {
      margin-top: 10px !important;
    }
  }

  .date-text {
    line-height: 2;
  }
}

.custom-mt {
  margin-top: 0.4rem;
}

.react-datepicker-wrapper {
  margin: auto !important;
}

#refvalue-modal {
  min-width: 750px;
}

#invoicePreviewModal {
  min-width: 750px;
}

#invoiceStatusModal {
  margin: 2rem auto;
  min-width: 930px;

  p {
    margin-bottom: 0.25rem;
  }

  td {
    max-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .rc-switch {
    width: 30px !important;
    height: 15px !important;
    line-height: 10px;
    border-radius: 20px 20px;
  }

  .rc-switch-inner {
    font-size: 12px;
    left: 24px;
    top: 0;
  }

  .rc-switch:after {
    width: 11px !important;
    height: 11px !important;
    left: 0px !important;
    top: 1px !important;
    margin-left: 2px !important;
  }

  .rc-switch-checked .rc-switch-inner {
    left: 6px;
  }

  .rc-switch-checked:after {
    left: 13px !important;
  }

  .rc-switch-label {
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
  }
}

#reftestQuestionsModal {
  &.modal-dialog {
    max-width: 590px;

    .ReactTable .rt-td {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .questions-table {
      max-height: 400px;
      overflow: auto;
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 15px;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141);
  }

  .question-text-container {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: pre-wrap;

    p {
      padding-right: 30px;
    }

    &:hover {
      .delete-question-item {
        opacity: 1;
      }
    }

    span {
      cursor: default;

      &.delete-question-item {
        opacity: 0;
        transition: opacity 0.4s;
        cursor: pointer;
        padding: 0 10px;
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }

  .question-checkbox__container {
    padding: 10px 0;
    display: flex;
    justify-content: center;
  }

  .header-asterisk {
    color: red;
    font-weight: 400;
  }

  .custom-control-input {
    cursor: pointer;
  }
}

#orderStatusModal {
  max-width: 620px;

  p {
    margin-bottom: 0.25rem;
  }
}

#refBundleModal {
  max-width: 600px;

  p {
    margin-bottom: 0.25rem;
  }
}

#orderStatusModal.order-modal {
  max-width: 880px;
}

#valueModal {
  margin: 19.75rem auto;
  width: 20rem;
}

#accountTypeModal {
  max-width: 810px;

  .form-control {
    padding: 5px 10px;
  }

  .modal-body {
    padding: 10px 10px 0px 10px;
    background: #0000001a;

    .acct-input-container {
      margin-bottom: 10px;
      max-width: 390px;
      background-color: white;
      border: 1px solid #00000029;
      border-radius: 3px;
    }
  }
}

#viewTestResult {
  margin: 5.75rem auto;

  .modal-body {
    max-height: 47em;
    overflow: auto;
    padding: 0.5rem 2.75rem;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141);
  }
}

#speciesModal {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141);
  }
}

.test-text-result {
  line-height: 15px;
  margin: 15px 0;
}

.test-result-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
}

.test-result-flag {
  width: 30%;
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  p {
    text-align: center;
    align-self: center;
    margin: 0;
    margin-bottom: 8px;
  }
}

input.test-result-flag {
  align-self: center;
}

.simple-icon-btn {
  background: transparent;
  border: 0;
  color: #bababa;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #656565;
  }
}

.test-range-result {
  background: rgba(208, 228, 242, 0.41);
  background: -moz-linear-gradient(
    left,
    rgba(208, 228, 242, 0.41) 0%,
    rgba(208, 228, 242, 0.56) 33%,
    rgba(89, 194, 114, 0.71) 33%,
    rgba(89, 194, 114, 0.71) 50%,
    rgba(89, 194, 114, 0.71) 67%,
    rgba(203, 214, 226, 0.85) 67%,
    rgba(203, 214, 226, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(208, 228, 242, 0.41)),
    color-stop(33%, rgba(208, 228, 242, 0.56)),
    color-stop(33%, rgba(89, 194, 114, 0.71)),
    color-stop(50%, rgba(89, 194, 114, 0.71)),
    color-stop(67%, rgba(89, 194, 114, 0.71)),
    color-stop(67%, rgba(203, 214, 226, 0.85)),
    color-stop(100%, rgba(203, 214, 226, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(208, 228, 242, 0.41) 0%,
    rgba(208, 228, 242, 0.56) 33%,
    rgba(89, 194, 114, 0.71) 33%,
    rgba(89, 194, 114, 0.71) 50%,
    rgba(89, 194, 114, 0.71) 67%,
    rgba(203, 214, 226, 0.85) 67%,
    rgba(203, 214, 226, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(208, 228, 242, 0.41) 0%,
    rgba(208, 228, 242, 0.56) 33%,
    rgba(89, 194, 114, 0.71) 33%,
    rgba(89, 194, 114, 0.71) 50%,
    rgba(89, 194, 114, 0.71) 67%,
    rgba(203, 214, 226, 0.85) 67%,
    rgba(203, 214, 226, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(208, 228, 242, 0.41) 0%,
    rgba(208, 228, 242, 0.56) 33%,
    rgba(89, 194, 114, 0.71) 33%,
    rgba(89, 194, 114, 0.71) 50%,
    rgba(89, 194, 114, 0.71) 67%,
    rgba(203, 214, 226, 0.85) 67%,
    rgba(203, 214, 226, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(208, 228, 242, 0.41) 0%,
    rgba(208, 228, 242, 0.56) 33%,
    rgba(89, 194, 114, 0.71) 33%,
    rgba(89, 194, 114, 0.71) 50%,
    rgba(89, 194, 114, 0.71) 67%,
    rgba(203, 214, 226, 0.85) 67%,
    rgba(203, 214, 226, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d0e4f2', endColorstr='#cbd6e2', GradientType=1);
  height: 15px;
  display: flex;
  position: relative;
  margin: 15px 0;
}

.test-res-thumb {
  position: absolute;
  height: 15px;
  width: 6px;
}

.test-res-thumb-max {
  transform: translateX(-6px);
}

.test-res-center {
  width: 34%;
  position: relative;
  border-left: 2px solid #28a74563;
  border-right: 2px solid #28a74563;
}

.test-min-max {
  font-size: 11px;
  font-weight: 700;
  position: absolute;
  top: -15px;
  color: rgba(40, 167, 69, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.test-min {
  left: 0;
  transform: translateX(-35%);
}

.test-max {
  right: 0;
  transform: translateX(35%);
}

.test-res {
  position: absolute;
  bottom: -16px;
  font-size: 12px;
  font-weight: 700;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-input {
  &:focus {
    border-color: rgba(226, 134, 59, 0.6) !important;

    ~ .email-input-text {
      display: none !important;
    }
  }
}

.alert-card-danger {
  border-left: calc(1rem - 1px) solid $error-color;
}

.alert-card-info {
  border-left: calc(1rem - 1px) solid $info-color;
}

.order-test-id {
  margin-left: 5rem;
}

#orderStatusModal {
  .paid-checkbox.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $success-color;
    border-color: $success-color;
  }

  .refunding-checkbox.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $warning-color;
    border-color: $warning-color;
  }

  .refunded-checkbox.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $dark-color;
    border-color: $dark-color;
  }

  .cancelled-checkbox.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $light-color;
    border-color: $light-color;
  }

  .billed-checkbox.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $error-color;
    border-color: $error-color;
  }

  .is-refund-checkbox.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
  }

  .paid-checkbox.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $success-color !important;
  }

  .refunding-checkbox.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $warning-color !important;
  }

  .refunded-checkbox.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $dark-color !important;
  }

  .cancelled-checkbox.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $light-color !important;
  }

  .billed-checkbox.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $error-color !important;
  }

  .is-refund-checkbox.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $theme-color-1 !important;
  }
}

.value-row {
  border-bottom: 2px solid #00000012;

  &:hover {
    .edit-value {
      opacity: 1;
    }
  }
}

.edit-value {
  opacity: 0;
  transition: opacity 0.4s;
  position: absolute;
  top: 0px;
  right: 10px;
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.snow {
  $total: 200;
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  filter: drop-shadow(0 0 10px white);

  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-offset: random_range(-100000, 100000) * 0.0001vw;
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: calc($random-x + ($random-offset / 2));
    $random-yoyo-time: calc(random_range(30000, 80000) / 100000);
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random(10000) * 0.0001;
    $fall-duration: random_range(10, 30) * 1s;
    $fall-delay: random(30) * -1s;

    &:nth-child(#{$i}) {
      opacity: random(10000) * 0.0001;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
      }
    }
  }
}

.option-container {
  &:hover {
    span {
      opacity: 0.3;
    }
  }

  span {
    opacity: 0;
    font-size: 1.2em;
    line-height: 1em;
    transition: opacity 0.4s;
    cursor: pointer;
    padding: 0 5px;

    &:hover {
      opacity: 0.7;
    }
  }
}

.modal-captcha {
  box-shadow: none !important;
  width: fit-content !important;

  .modal-header {
    padding: 18px 28px;
    background-color: #145388;
    color: white;
  }

  .modal-content {
    border-radius: 5px !important;
  }

  .modal-footer {
    padding: 14px 28px;
  }
}

.result-modal-container {
  width: 100%;
  min-width: 400px;

  .form-control {
    padding: 5px 10px;
  }

  .examined-fields-button {
    font-size: 20px;
    font-weight: bold;
    height: 38px;
    width: 38px;
  }

  .values-button {
    font-size: 15px;
    font-weight: bold;
    width: 24px;
    padding: 0px;
  }
}

.images-modal-container {
  max-width: 65%;
  width: 100%;
  min-width: 350px;
}

.clinical-contractor-page {
  margin: 0 auto;

  .result-modal-container {
    border-left: 2px solid #d7d7d778;
    margin-bottom: 50px;
  }

  .images-modal-container {
    margin-bottom: 30px;
    max-width: 100%;
  }
}

.images-and-result-modals-container,
.pathology-viewer-modal {
  .modal-dialog {
    max-width: 1400px;
    width: 100%;

    .modal-body {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0;

      .pv-labels-selection {
        max-width: 100%;
      }
    }
  }

  .result-modal-container {
    max-width: 35%;
    width: 100%;
    min-width: 440px;
    border-left: 2px solid #d7d7d778;
    padding: 20px;
  }

  .images-modal-container {
    max-width: 65%;
    width: 100%;
    min-width: 500px;
    padding: 20px;
  }
}

.message-options-container {
  display: flex;
  justify-content: space-between;

  &__inner-block {
    display: flex;

    & .require-response-block {
      align-self: center;
    }
  }
}

@media screen and (max-width: 1400px) {
  .pathology-viewer-modal {
    .modal-dialog {
      max-width: 900px;
      width: 100%;

      .modal-body {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        .pv-labels-selection {
          max-width: 240px;
        }
      }

      .message-options-container {
        flex-direction: column;

        &__inner-block {
          flex-direction: column;
          justify-content: flex-end;

          & .require-response-block {
            align-self: flex-start;
          }
        }

        &__send-button {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .images-and-result-modals-container {
    .modal-dialog {
      max-width: 700px;
      width: 100%;

      .modal-body {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .result-modal-container {
      max-width: 100%;
      width: 100%;
      min-width: 440px;
      padding: 20px;
      margin: auto;
      border-left: 0;
    }

    .images-modal-container {
      max-width: 100%;
      width: 100%;
      min-width: 600px;
      border-bottom: 2px solid #d7d7d778;
      padding: 20px;
    }
  }
}

.fullpage {
  height: 100% !important;
}

.border-for-select {
  .react-select__control {
    border-color: red !important;
  }
}

.image-osd {
  display: block;
  margin: 0 auto;
  height: 100px;
  width: 100px;
  alt: 'check_mark';

  &_title {
    margin-top: 0.4rem;
    text-align: center;
    font-size: 2.2rem;
    color: rgb(36, 35, 35);
  }
}

.div-image-osd {
  margin: auto;
}

.password-req {
  p {
    color: grey;
    margin: 0;
  }

  margin: 0 0 24px;
}

.modal-broker-user .modal-dialog {
  max-width: 576px;
}

.badge-top-left.user-list-badge {
  top: -7px;
  left: -7px;
}

.user-list-badge {
  top: 0px;
  left: -1px;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.w-5 {
  width: 5% !important;
}

.header-custom {
  flex: 1 1 auto !important;
  padding: 0 1.25rem !important;
  font-weight: bold !important;
}

.cursor-p {
  cursor: pointer !important;
}

.tooltip {
  pointer-events: none !important;
}

.w-33 {
  width: 33%;
}

.border-bottom-bold {
  border-width: 0 0 1px;
  border-style: solid;
  border-image: radial-gradient(black, white) 1;
}

.image-individual {
  min-height: 1px;
  width: 100%;
  overflow: auto;
  border: 1px solid lightgrey;
  padding: 0.2rem 0.2rem;
}

.image-label-individual {
  padding-top: 0.5rem;
  font-size: x-large;
}

.pathology-label {
  font-size: large;

  .assign-btn {
    margin-left: auto;
    margin-top: -5px;
    width: 100%;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: gray;
    border-color: gray;

    &:hover {
      background-color: rgb(100, 100, 100) !important;
      border-color: rgb(100, 100, 100) !important;
    }

    &:active {
      background-color: rgb(80, 80, 80) !important;
      border-color: rgb(80, 80, 80) !important;
    }

    &.disabled {
      background-color: rgb(168, 168, 168) !important;
      border-color: rgb(168, 168, 168) !important;
    }
  }

  .custom-label-checkbox {
    width: 15px;
    height: 15px;
  }
}

.pathology-viewer-actions {
  button {
    margin-bottom: 8px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.not-available-pathology {
  margin: 8px auto;
}

.ReactGridGallery_tile-icon-bar {
  z-index: 100000;
}

.image-box-custom-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
}

.card .card-body {
  padding: 12px 16px;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.btn-purple {
  background-color: rgb(155, 0, 155);
  border-color: rgb(155, 0, 155);
  color: #fff;

  &:hover {
    background-color: rgb(121, 0, 121);
    border-color: rgb(121, 0, 121);
  }

  &:active {
    background-color: rgb(90, 0, 90) !important;
    border-color: rgb(90, 0, 90) !important;
  }

  &:disabled {
    background-color: rgb(150, 43, 150);
    border-color: rgb(150, 43, 150);
  }
}

.btn-orange {
  background-color: #e2863b;
  border-color: #e2863b;
  color: #fff;

  &:hover {
    background-color: #c47433;
    border-color: #c47433;
  }

  &:active {
    background-color: #a8642c !important;
    border-color: #a8642c !important;
  }

  &:disabled {
    background-color: #db9e6c;
    border-color: #db9e6c;
  }
}

.fancy-border {
  border: solid 1px gray;
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 8px;
  white-space: pre-wrap;
  background-color: #fffde0;

  .content-header {
    font-weight: bold;
  }
}

#orderStatusModal,
#invoiceStatusModal {
  .modal-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.pdf-loader {
  min-height: 34px;
  min-width: 34px;
  margin-right: 32px;
}

.addon-button-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  margin-right: 16px;
}

.show-password-icon-btn.active {
  color: #e27b7f;
}

.pathology-selected-img {
  height: 70px;
  border-radius: 0.5rem;

  &__container {
    margin: 0 0 0.5rem 0.5rem;
    position: relative;
    display: inline-block;
    border-radius: 0.5rem;

    &:hover &-after {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 0.5rem;

      & .simple-icon-close {
        color: #bababa;
        font-size: 30px;
      }
    }

    &-after {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
    }
  }
}

.pathology-selected-img {
  height: 70px;
  border-radius: 0.5rem;

  &__container {
    margin: 0 0 0.5rem 0.5rem;
    position: relative;
    display: inline-block;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover &-after {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 0.5rem;

      & .simple-icon-close {
        color: #bababa;
        font-size: 30px;
      }
    }
  }

  &__text {
    margin-top: 0.2rem;
    color: #bababa;
    text-wrap: nowrap;
  }
}

.test-event-communication {
  &_card {
    &__user-event {
      background-color: $color-event-main-dark-blue;

      &.card:hover {
        background-color: $color-event-main-dark-blue;
      }
    }

    &__c-admin-event {
      background-color: $color-event-main-light-red;

      &.card:hover {
        background-color: $color-event-main-light-red;
      }
    }

    &__other-event {
      background-color: #fff;

      &.card:hover {
        background-color: #fff;
      }
    }

    &-title {
      &__user-admin-event {
        color: $color-event-main-yellow;
        font-weight: 700;
      }

      &__other-event {
        color: #000;
        font-weight: 700;
      }
    }

    &-body {
      font-size: 0.9rem;
      line-height: 1.3rem;

      &__user-admin-event {
        @extend .test-event-communication_card-body;
        color: #fff;

        & a {
          color: #fff;

          &:hover {
            color: rgb(219, 219, 219);
          }
        }
      }

      &__other-event {
        @extend .test-event-communication_card-body;
        color: #000;
      }
    }
  }

  &_massages-container {
    max-height: 450px;
    overflow: auto;
    padding: 1rem;
  }
}

.answer-wrapper {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
}

.unread-message {
  box-shadow: 0 1px 15px $info-color, 0 1px 6px $info-color;
  border-radius: 0.75rem;
}

.text-color-gold {
  color: $color-event-main-yellow;
}

.text-color-custom-blue {
  color: $color-event-blue;
}

.text-color-custom-red {
  color: $color-event-main-light-red;
}

.actions-box-border {
  border: 1px solid #d7d7d7;
  width: 100%;
  border-radius: 12px;
  text-align: center;
}

.self-dimmed {
  opacity: 0.4;
}

.dimmed p {
  opacity: 0.4;
}

.dimmed div {
  opacity: 0.4 !important;
}

.is-any-unread-notification {
  &_icon {
    color: #c43d4b;
    transition: all 0.3s ease;
  }

  &_badge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: auto;
    height: 16px;
    line-height: 10px;
    font-size: 10px;
    right: 8px;
    top: 2px;
    transition: all 0.3s ease;
  }

  &:hover {
    .is-any-unread-notification_icon {
      color: #d31125;
      transform: translateY(-3px);
    }

    .is-any-unread-notification_badge {
      background-color: #d31125;
      transform: translateY(-3px);
    }
  }
}

.message-replies-block {
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  color: $info-color;

  &:hover {
    color: #49bcce;
  }
}

.button__close-reply-form {
  font-size: 30px;
  color: rgb(100, 100, 100);

  &:hover {
    color: rgb(110, 110, 110);
  }
}

.finalized-text {
  font-size: 1em;
  white-space: nowrap;
}

.sentry-error-embed-wrapper {
  z-index: 10000 !important;
}

.expandable-icon {
  display: inline-block;
  font-size: 1.4em;
  padding: 0 8px;
  line-height: 1em;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.1) 0.5s;
  cursor: pointer;

  &.active {
    transform: rotate(180deg);
  }

  &::before {
    line-height: 1.4em;
    text-align: center;
  }
}

.combo-header-container {
  .card-body {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .collapse {
    padding-bottom: 8px;
  }

  & > * > .card-body {
    padding: 12px 16px;
  }
}

.combo-expand-gutter {
  border-top: 1px solid #d7d7d7;
  height: 24px;
}

.osd-container {
  background: #f8f8f8;
  height: 80vh;
  display: flex;
  flex-direction: column;

  .osd-toolbar {
    justify-content: space-between;

    i {
      font-size: 16px;
    }

    padding: 4px 16px;
  }

  .osd-embed {
    height: 100%;
    flex-grow: 1;

    div {
      opacity: 1 !important;
    }
  }
}

.openseadragon-canvas {
  outline: none;
}

.referencestrip {
  margin-bottom: 0 !important;
  position: absolute !important;
}

.openseadragon-overlay {
  border: 5px solid var(--label-color);
  opacity: 0.75;
  border-radius: 5px;
}

#root {
  --label-color: #270000;
}

.label-0 {
  --label-color: #c51111;
}

.label-1 {
  --label-color: #132ed1;
}

.label-2 {
  --label-color: #117f2d;
}

.label-3 {
  --label-color: #ed54ba;
}

.label-4 {
  --label-color: #ef7d0e;
}

.label-5 {
  --label-color: #f6f658;
}

.label-6 {
  --label-color: #3f474e;
}

.label-7 {
  --label-color: #d6e0f0;
}

.label-8 {
  --label-color: #6b31bc;
}

.label-9 {
  --label-color: #71491e;
}

.label-10 {
  --label-color: #38fedb;
}

.label-11 {
  --label-color: #50ef39;
}

.label-12 {
  --label-color: #731b12;
}

.label-13 {
  --label-color: #d292b3;
}

.label-14 {
  --label-color: #fffebe;
}

.label-15 {
  --label-color: #708494;
}

.label-16 {
  --label-color: #928776;
}

.label-17 {
  --label-color: #ec7578;
}

.label-18 {
  --label-color: #617218;
}

.label-19 {
  --label-color: #1d9853;
}

.label-deleted {
  --label-color: rgba(0, 0, 0, 0.3) !important;
}

.bundle-content-item {
  margin-bottom: 2px;
  margin-left: 10%;
  color: rgb(110, 110, 110);
}

.discount-table {
  &.discount-header {
    font-weight: bold;
  }

  .discount-col {
    flex-basis: 0;
    flex-grow: 1;
    padding: 2px 4px;
    text-align: center;
  }

  span {
    font-size: 1.2em;
    font-weight: bold;
  }
}

.border-top-separator {
  border-top: 1px solid var(--separator-color) !important;
}

.column-refvalue {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.refvalue-remove {
  position: absolute;
  right: 0;

  &:hover {
    cursor: pointer;
  }
}

.small-range-result {
  flex-grow: 1;
  min-width: 60px;

  &-xs {
    max-width: 60px;
  }

  &-md {
    max-width: 120px;
  }

  &-lg {
    max-width: 180px;
  }

  position: relative;
  height: 1.2rem;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#d8ebf9),
    color-stop(33%, #d8ebf9),
    color-stop(33%, #94b5de),
    color-stop(50%, #94b5de),
    color-stop(67%, #94b5de),
    color-stop(67%, #d8ebf9),
    to(#d8ebf9)
  );
  background: -o-linear-gradient(
    left,
    #d8ebf9 0%,
    #d8ebf9 33%,
    #94b5de 33%,
    #94b5de 50%,
    #94b5de 67%,
    #d8ebf9 67%,
    #d8ebf9 100%
  );
  background: linear-gradient(
    to right,
    #d8ebf9 0%,
    #d8ebf9 33%,
    #94b5de 33%,
    #94b5de 50%,
    #94b5de 67%,
    #d8ebf9 67%,
    #d8ebf9 100%
  );

  &-thumb {
    position: absolute;
    width: 3px;
    height: 100%;
    background-color: #e83d24;

    &.normal {
      background-color: #2e429b;
    }
  }
}

.displayregion {
  z-index: 1000 !important;
}

.children-flex-basis-0 * {
  flex-basis: 0;
  flex-grow: 1;
}

.overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.add-answer-button {
  cursor: pointer;

  svg {
    fill: $theme-color-1;
    transition: fill 0.4s;
  }

  &:hover {
    svg {
      fill: #053156;
    }
  }
}

.receiving {
  &-alternatives {
    background: #e3f5f7;
    border-radius: 4px;

    .alternative-row {
      border: 1px solid gray;
      border-radius: 2px;
      margin-right: 8px;
    }
  }

  &-warning {
    background: #fffeb4;
    border-radius: 4px;
  }

  &-vars {
    flex-grow: 1;
    flex-wrap: wrap;

    &-item {
      flex: 1 1 0;
      flex-shrink: 1;
      margin-right: 8px;

      p {
        margin: 0;
        margin-right: 4px;
      }

      input {
        padding: 4px;
        min-width: 100px;
      }
    }
  }
}

.today-samples {
  padding-bottom: 150px;
  padding-top: 8px;

  .today-sample {
    border: 1px solid #d7d7d7;
    border-radius: 4px;

    p {
      margin: 0;
    }

    &-header {
      border-bottom: 1px solid #d7d7d7;
      text-align: center;

      > * {
        margin: 0 4px !important;
      }

      &-id {
        text-align: left;
        margin-left: 0 !important;
      }
    }

    &-tests {
      &-item {
        padding: 4px 0;
        min-height: 37px;

        &:last-child {
          border-bottom: 0 !important;
        }

        border-bottom: 1px solid #e2e2e2;

        &-id {
          width: 100%;
          max-width: 140px;
          flex-shrink: 0;
        }

        &-vars {
          flex-grow: 1;

          &-item {
            flex: 1 1 150px;
            min-width: 200px;
            margin-right: 8px;

            p {
              margin-right: 6px;
              display: block;
              white-space: nowrap;
            }

            &-input {
              padding: 4px;
              min-width: 100px;
            }
          }
        }
      }
    }
  }
}

.pills-box {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  border: 1px solid #e2e2e2;
  background: rgb(245, 245, 245);
  border-radius: 4px;
  min-height: 60px;

  .pill {
    background-color: white;
    border-radius: 12px;
    padding: 4px 8px;
    margin: 2px;
    display: flex;
    align-items: center;
    height: fit-content;

    > span {
      margin-right: 2px;
    }

    .close {
      font-size: 1.2rem !important;
    }
  }
}

.shipment-requested-items-item {
  padding: 0.2rem 0;

  > p {
    margin: 0;
  }
}

.badge-purple {
  background-color: #922c88;
  color: white;
}

.badge-dark-blue {
  background-color: #4556ac;
  color: white;
}

.grid-gallery-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  user-select: none;

  .grid-gallery-image {
    position: relative;
    height: 150px;
    width: 150px;
    margin: 2px;

    &-check,
    &-glass {
      display: none;
      position: absolute;
      top: 4px;
      fill: rgba(255, 255, 255, 0.8);
      cursor: pointer;

      &:hover {
        display: block;
        fill: rgba(255, 255, 255, 1);

        &.active {
          fill: #3a77da;
        }
      }

      &.active {
        display: block;
        fill: #4285f4;
      }
    }

    &-check {
      left: 4px;
    }

    &-glass {
      right: 4px;
    }

    &-container {
      > div {
        opacity: 0.4;
      }

      > div:hover {
        opacity: 1;
      }

      height: 100%;

      img {
        height: 100%;
        z-index: 200;
      }
    }

    &:hover {
      .grid-gallery-image-check,
      .grid-gallery-image-glass {
        display: block;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6));
      }
    }
  }
}

.grid-gallery-viewer {
  .modal-dialog {
    max-width: 90% !important;
    // width: fit-content !important;
    box-shadow: none !important;

    .modal-content {
      background: transparent !important;
    }
  }

  &-container {
    height: 50vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-check {
    display: none;
    position: absolute;
    top: 8px;
    left: 8px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6));
    fill: rgba(255, 255, 255, 0.8);
    cursor: pointer;

    &:hover {
      display: block;
      fill: rgba(255, 255, 255, 1);

      &.active {
        fill: #3a77da;
      }
    }

    &.active {
      display: block;
      fill: #4285f4;
    }
  }

  &-image-container {
    position: relative;

    &:hover .grid-gallery-viewer-check {
      display: block;
    }
  }

  &-image {
    display: block;
  }

  &-control {
    width: 40px;
    height: 100%;
    color: white;
    font-size: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &-control:hover {
    background: rgba(36, 35, 35, 0.2);
  }

  &-close-button {
    color: white !important;
    font-size: 2rem;
    width: fit-content;
    margin-left: auto;
  }
}

.today-priority-badge {
  margin-top: -2px;
  vertical-align: top;
}

.orders-priority-badge {
  position: absolute;
  left: -8px;
  margin-top: -24px;
}

.react-select--is-disabled {
  filter: brightness(0.9);
}

.quiet-input {
  width: 100%;
  display: inline;
  line-height: 1.5;
  min-height: 29px;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 1.6px;

  &:hover {
    border-color: $theme-color-2;
  }
}

.overlay-opacity-item {
  p {
    min-width: 20%;
  }

  &-input {
    max-width: 20%;
  }
}

.loading-backdrop {
  filter: opacity(0.6);
}

.clinicalval-markdown-btn {
  position: absolute;
  right: 8px;
  top: 0;
  padding: 5px 4px 3px 6px;
  margin-top: 10px;
}

@media (min-width: 576px) {
  .markdown-modal {
    max-width: 660px;
  }
}

.quiet-input {
  width: 100%;
  display: inline;
  line-height: 1.5;
  min-height: 29px;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 1.6px;

  &:hover {
    border-color: $theme-color-2;
  }
}

.dropzone a.remove * {
  cursor: pointer !important;
}

.dropdown-toggle-split {
  border-left: 1px solid #ffffff4f;
}

.overlay-opacity-input {
  max-width: 20%;
}

.expandable {
  margin: 8px 0;
  border: 1px solid #d7d7d7;
  border-radius: 8px;

  .expandable-body {
    padding: 8px;
    margin-left: 24px;
  }
}

.dropdown-toggle-split {
  border-left: 1px solid #ffffff4f;
}

.user-permissions {
  &-item {
    flex: 1 0 0;
    min-width: 40%;
    margin-right: 24px;
  }
}

.border-dark {
  border-color: #ccc !important;
}

.dropdown-toggle-split {
  border-left: 1px solid #ffffff4f;
}

.border-dark {
  border-color: #ccc !important;
  background: #eee;
}

.border-bottom-only {
  border-width: 0 !important;
  border-bottom-width: 1px !important;
}

.numberFormat-on-pd {
  font-size: 0.8rem;
  padding: 0.75rem 0.75rem 0.75rem;
  color: #212121;
  outline: initial !important;
  border: 1px solid #d7d7d7 !important;
  width: 100%;
}

.edit_text_button {
  font-size: 12px;
  display: inline-block;
  padding-right: 3px;
  line-height: 1;
  cursor: pointer;
  color: #000;

  &:hover {
    border-bottom: 1px solid #888;
  }

  &.highlight {
    color: #c51111;

    &:hover {
      border-color: #c51111;
    }
  }
}

.remove_field {
  width: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;

  .iconsminds-close:before {
    font-size: 20px;
    color: white;
  }

  div {
    border: 1px solid #dc3545;
    border-left: none;
    background-color: #dc3545;
    cursor: pointer;
    flex: 1;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.add_more_test {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;

  span {
    font-size: 12px;
    color: #666;
    cursor: pointer;
    border-bottom: 1px solid #666;
  }
}

.is_duplicate {
  .react-select__control {
    border-color: #dc3545 !important;
  }
}

@media (min-width: 576px) {
  #reference_task_modal {
    max-width: 600px;
  }

  .ref_test_status_container {
    display: flex;

    .ref_test_container {
      flex: 2;
    }

    .ref_test_status {
      flex: 1;
    }
  }
}

.scheduling_input {
  height: 40px;
  margin-top: 3px;
  font-weight: bold;
}

.schedule-container {
  background: white;
  padding: 20px 15px 100px 15px;
  position: relative;
  margin-bottom: 20px;

  .schedule_date {
    font-weight: bold;
    font-size: 16px !important;
  }
}

.input-small {
  padding: 0.25rem 0.5rem !important;
}

.button-small {
  padding: 0.2rem 1rem !important;
}

.task_distribution_view {
  display: flex;
  margin-bottom: 20px;

  div {
    width: 30%;
    height: 30px;
    background: #eee;
    padding: 0 10px;
    margin-bottom: 0;
    display: flex;
    line-height: 1;
    font-weight: bold;
    font-size: 12px;
    align-items: center;
    border: 1px solid #c3c3c3;
  }

  span {
    height: 30px;
    padding: 10px;
  }
}

.today-sample-tests-item-id {
  max-width: 200px !important;

  small {
    display: block;
    color: rgb(206, 79, 185);
    font-size: 12px;
    font-weight: bold;
  }
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}

#reftestRequirementsModal {
  width: 700px;

  .modal-body {
    padding-top: 10px;

    .form-control {
      height: 40px;
    }
  }

  .rt-tr {
    padding: 0 10px;
  }
}

.comment-editor {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 8px !important;
  min-height: 400px;
  height: 100%;
}

.expression {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 0 4px;
  margin-bottom: 8px;
}

.expression-error {
  color: red;
  border-bottom: 1px solid #d7d7d7;
}

.lab-selection-field .dropdown-menu {
  max-height: 300px;
  overflow: scroll;
}

span.manual-review-var {
  font-size: 11pt;
  font-weight: 600;
}

.form-control.dense {
  padding: 4px;
}

.checkbox-container {
  background-color: #eee;
  padding: 10px;

  .form-check {
    width: 100%;
  }

  input[type='checkbox'] {
    margin-right: 10px;
    zoom: 1.3;
  }

  border-top: 1px solid #ccc;
  border-bottom: 1px solid white;

  label {
    display: block;
    width: 100%;
    font-weight: normal;
    font-size: 16px;
  }

  &:nth-child(2n + 1) {
    background-color: #f6f6f6;
  }

  &:last-child {
    border-bottom: 1px solid #ccc;
  }
}

.patient_note_section {
  min-width: 200px;
  max-width: 220px;

  p:last-child {
    font-size: 13px;

    span {
      font-weight: bold;
    }
  }
}

.macro-preview {
  overflow: hidden;
  border: 1px solid #ccc;
  padding: 5px;

  img {
    width: 100%;
  }
}

kbd {
  border-bottom: 0.3rem solid #808080;
  border-left: 0.3rem solid #adadad;
  border-right: 0.3rem solid #6e6e6e;
  padding-top: 0.1rem;
  font-weight: bold;
  cursor: pointer;

  &.reftest-modal-kbd {
    background-color: #212529;
  }

  &:hover() {
    border-color: var(--label-color);
  }

  &.selected {
    border-color: var(--label-color);
  }

  &.label-btn {
    background-color: var(--label-color);

    &:hover,
    &.selected {
      border-color: var(--label-color);
    }
  }
}

.hotkey-input {
  cursor: pointer;
}

.hotkey-input:hover {
  opacity: 0.85;
}

.hotkey-input:focus {
  outline: 2px solid #2684ff;
  background-color: gray;
  border-bottom: 2px solid #a3a3a3;
}

.value-modal-row {
  width: 100%;
  padding: 20px;
  background-color: #fafafa;
  margin-top: 10px;
  border-radius: 1rem;
}

.br-6 {
  border-radius: 6px;
}

.br-8 {
  border-radius: 8px;
}

.invoice-page-modal .modal-content {
  border-radius: 0 !important;
}

.block {
  display: block;
}

.bold {
  font-weight: bold;
}

.truncate-long-text {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invoice-page-modal .modal-content {
  border-radius: 0 !important;
}

.recalculated_table {
  max-height: calc(100vh - 400px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.rounded-5 {
  border-radius: 5px !important;
}

.react-datepicker__input-container input {
  border-radius: 5px !important;
  padding: 0.55rem !important;
}

.react-datepicker,
.react-datepicker__month-container {
  border-radius: 15px !important;
}

.react-datepicker__header {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.interpretive-relations-list {
  .interpretive-relation-item {
    position: relative;
    transition: all 300ms linear;

    .overflowed-close-button {
      transition: all 300ms linear;
      display: none;
      position: absolute;
      top: -8px;
      right: -4px;
      z-index: 1;
      background: red;
      height: 16px;
      width: 16px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      color: white;
      cursor: pointer;
      font-weight: bolder;

      &:hover {
        background: darkred;
      }
    }

    &:hover {
      .overflowed-close-button {
        display: flex;
      }
    }
  }
}

.border-validation-success {
  background-color: #cfc;
  border: 1px solid green;
}

.border-validation-error {
  background-color: #fcc;
  border: 1px solid red;
}

.border-validation-info {
  background-color: #ccf;
  border: 1px solid blue;
}

@for $i from 0 through 100 {
  .moichor-gap-#{$i} {
    gap: #{$i * 2}em;
  }

  .moichor-m-#{$i} {
    margin: #{$i}em;
  }

  .moichor-ml-#{$i} {
    margin-left: #{$i}em;
  }

  .moichor-mr-#{$i} {
    margin-right: #{$i}em;
  }

  .moichor-mt-#{$i} {
    margin-top: #{$i}em;
  }

  .moichor-mb-#{$i} {
    margin-bottom: #{$i}em;
  }

  .-moichor-m-#{$i} {
    margin: -#{$i}em;
  }

  .-moichor-ml-#{$i} {
    margin-left: -#{$i}em;
  }

  .-moichor-mr-#{$i} {
    margin-right: -#{$i}em;
  }

  .-moichor-mt-#{$i} {
    margin-top: -#{$i}em;
  }

  .-moichor-mb-#{$i} {
    margin-bottom: -#{$i}em;
  }
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1 1 0%;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
  overflow: auto;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-stretch {
  align-items: stretch;
}

.w-full {
  width: 100%;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: auto !important;
}

.pathology-viewer-modal {
  .osd-container {
    background-color: white;
    h1.title {
      font-weight: 700;
      margin-bottom: 0;
      padding-bottom: 0;
      display: inline-block;
    }
    .action-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.dz-preview.dz-processing.dz-image-preview.dz-success.dz-complete {
  z-index: 0;
}
