// Define color palette
$colors: (
  "transparent": transparent,
  "black": #000,
  "white": #fff,
  "gray-50": #f9fafb,
  "gray-100": #f3f4f6,
  "gray-200": #e5e7eb,
  "gray-300": #d1d5db,
  "gray-400": #9ca3af,
  "gray-500": #6b7280,
  "gray-600": #4b5563,
  "gray-700": #374151,
  "gray-800": #1f2937,
  "gray-900": #111827,
  "red-50": #fef2f2,
  "red-100": #fee2e2,
  "red-200": #fecaca,
  "red-300": #fca5a5,
  "red-400": #f87171,
  "red-500": #ef4444,
  "red-600": #dc2626,
  "red-700": #b91c1c,
  "red-800": #991b1b,
  "red-900": #7f1d1d,
  "green-50": #ecfdf5,
  "green-100": #d1fae5,
  "green-200": #a7f3d0,
  "green-300": #6ee7b7,
  "green-400": #34d399,
  "green-500": #10b981,
  "green-600": #059669,
  "green-700": #047857,
  "green-800": #065f46,
  "green-900": #064e3b,
  "blue-50": #eff6ff,
  "blue-100": #dbeafe,
  "blue-200": #bfdbfe,
  "blue-300": #93c5fd,
  "blue-400": #60a5fa,
  "blue-500": #3b82f6,
  "blue-600": #2563eb,
  "blue-700": #1d4ed8,
  "blue-800": #1e40af,
  "blue-900": #1e3a8a,
  "slate-50": #f8fafc,
  "slate-100": #f1f5f9,
  "slate-200": #e2e8f0,
  "slate-300": #cbd5e1,
  "slate-400": #94a3b8,
  "slate-500": #64748b,
  "slate-600": #475569,
  "slate-700": #334155,
  "slate-800": #1e293b,
  "slate-900": #0f172a,
);

// Generate background color classes
@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: #{$color};
  }
}

// Generate text color classes
@each $name, $color in $colors {
  .text-#{$name} {
    color: #{$color};
  }
}

// Generate border color classes
@each $name, $color in $colors {
  .border-#{$name} {
    border-color: #{$color};
  }
}

// Generate divide color classes (for flex/grid)
@each $name, $color in $colors {
  .divide-#{$name} > :not([hidden]) ~ :not([hidden]) {
    border-color: #{$color};
  }
}
