/* Multi-Column Layouts */
@for $i from 1 through 12 {
  .column-#{$i} {
    columns: $i;
  }
}

/* Custom Column Widths */
.column-auto {
  columns: auto;
}
.column-[size] {
  columns: [size];
} /* e.g., column-3xs for a specific size */

.masonry-columns {
  columns: 2; /* Number of columns */
  column-gap: 1rem; /* Space between columns */
}
.masonry-item {
  break-inside: avoid; /* Prevents item from breaking across columns */
  margin-bottom: 1rem; /* Space between items in each column */
}
