// Define border radius values
$rounded-sizes: (
  "none": 0,
  "sm": 0.125rem,
  "": 0.25rem,
  // default
  "md": 0.375rem,
  "lg": 0.5rem,
  "xl": 0.75rem,
  "2xl": 1rem,
  "3xl": 1.5rem,
  "full": 9999px,
);

// Generate rounded classes
@each $name, $size in $rounded-sizes {
  .rounded-#{$name} {
    border-radius: #{$size};
  }
}

// Generate rounded-t classes (top)
@each $name, $size in $rounded-sizes {
  .rounded-t-#{$name} {
    border-top-left-radius: #{$size};
    border-top-right-radius: #{$size};
  }
}

// Generate rounded-r classes (right)
@each $name, $size in $rounded-sizes {
  .rounded-r-#{$name} {
    border-top-right-radius: #{$size};
    border-bottom-right-radius: #{$size};
  }
}

// Generate rounded-b classes (bottom)
@each $name, $size in $rounded-sizes {
  .rounded-b-#{$name} {
    border-bottom-right-radius: #{$size};
    border-bottom-left-radius: #{$size};
  }
}

// Generate rounded-l classes (left)
@each $name, $size in $rounded-sizes {
  .rounded-l-#{$name} {
    border-top-left-radius: #{$size};
    border-bottom-left-radius: #{$size};
  }
}

// Generate rounded-tl classes (top-left)
@each $name, $size in $rounded-sizes {
  .rounded-tl-#{$name} {
    border-top-left-radius: #{$size};
  }
}

// Generate rounded-tr classes (top-right)
@each $name, $size in $rounded-sizes {
  .rounded-tr-#{$name} {
    border-top-right-radius: #{$size};
  }
}

// Generate rounded-br classes (bottom-right)
@each $name, $size in $rounded-sizes {
  .rounded-br-#{$name} {
    border-bottom-right-radius: #{$size};
  }
}

// Generate rounded-bl classes (bottom-left)
@each $name, $size in $rounded-sizes {
  .rounded-bl-#{$name} {
    border-bottom-left-radius: #{$size};
  }
}
