// Display Flex
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}

// Flex Direction
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}

// Flex Wrap
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

// Flex (Grow, Shrink, Basis)
.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}

// Flex Grow
@for $i from 0 through 1 {
  .flex-grow-#{$i} {
    flex-grow: #{$i};
  }
}

// Flex Shrink
@for $i from 0 through 1 {
  .flex-shrink-#{$i} {
    flex-shrink: #{$i};
  }
}

// Order
@for $i from 1 through 12 {
  .order-#{$i} {
    order: #{$i};
  }
}
.order-first {
  order: -9999;
}
.order-last {
  order: 9999;
}
.order-none {
  order: 0;
}

// Align Items
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

// Align Content
.content-start {
  align-content: flex-start;
}
.content-end {
  align-content: flex-end;
}
.content-center {
  align-content: center;
}
.content-between {
  align-content: space-between;
}
.content-around {
  align-content: space-around;
}
.content-evenly {
  align-content: space-evenly;
}

// Align Self
.self-auto {
  align-self: auto;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-stretch {
  align-self: stretch;
}
.self-baseline {
  align-self: baseline;
}

// Justify Content
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

// Space Between X
@for $i from 0 through 12 {
  .space-x-#{$i} > :not([hidden]) ~ :not([hidden]) {
    margin-right: #{$i * 0.25}rem;
  }
}

// Space Between Y
@for $i from 0 through 12 {
  .space-y-#{$i} > :not([hidden]) ~ :not([hidden]) {
    margin-top: #{$i * 0.25}rem;
  }
}

// Negative Margin for Space Between
@for $i from 0 through 12 {
  .-space-x-#{$i} > :not([hidden]) ~ :not([hidden]) {
    margin-right: -#{$i * 0.25}rem;
  }
  .-space-y-#{$i} > :not([hidden]) ~ :not([hidden]) {
    margin-top: -#{$i * 0.25}rem;
  }
}
