#Accession-Information-Box {
  min-width: 800px;
  box-shadow: none;
  .modal-content {
    border-radius: 5px;
  }
  .border-bottom {
    border-bottom: 1px solid #9e9e9e;
  }
  .btn {
    border-radius: 10px;
  }
}

.accessioning-table {
  tr:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
}

.bin-color {
  padding: 1px 9px 2px;
  font-size: 12.025px;
  font-weight: bold;
  white-space: nowrap;
  color: #ffffff;
  background-color: #999999;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.webcam-capture-btn {
  position: absolute;
  top: 40%;
  right: 10%;
  height: 100px;
  border-radius: 35px;
  width: 44px;
  display: none;
}

.floating-right {
  width: 80px;
  margin-right: -70px;
  background: lightgray;
  border-radius: 5px;
  .simple-icon-btn {
    color: black !important;
  }
}
